import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/TabsPage.vue'
import {store} from '@/store'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/tabs/welds'
    },
    {
        path: '/tabs/',
        component: TabsPage,
        children: [
            {
                path: '',
                redirect: '/tabs/welds'
            },
            {
                path: 'welds',
                component: () => import('@/views/WeldsPage.vue')
            },
            {
                path: 'welds/new',
                component: () => import('@/views/NewWeld.vue')
            },
            {
                path: 'welds/new/summary',
                component: () => import('@/views/NewWeldSummary.vue')
            },
            {
                name: 'WeldLog',
                path: 'log',
                component: () => import('@/views/WeldLog.vue')
            },
            {
                name: 'WeldSummary',
                path: 'log/:weld/summary',
                component: () => import('@/views/WeldSummary.vue'),
                props: true
            },
            {
                name: 'Settings',
                path: 'settings',
                component: () => import('@/views/SettingsPage.vue')
            },
            {
                name: 'Register',
                path: 'register',
                component: () => import('@/views/RegisterPage.vue')
            },
            {
                name: 'Login',
                path: 'login',
                component: () => import('@/views/LoginPage.vue')
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (store.state.auth === null && to.name !== 'Login') next({ name: 'Login' })
    // if (store.state.auth === null && to.name === 'WeldLog') next({ name: 'Login' })
    if (store.state.auth !== null && (to.name === 'Login' || to.name === 'Register')) next({ name: 'WeldLog' })
    else next()
})

export default router
